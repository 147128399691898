import React, { useState, useEffect, forwardRef } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment"
import { format, parse, isValid } from "date-fns";
import { ru } from 'date-fns/locale';
import "./DatePicker.scss";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({ date, onChange }) => {

    const parseDate = (input) => {
        if (!input) return null;
        const parsed = parse(input, 'dd.MM.yyyy', new Date());
        return isValid(parsed) ? parsed : null;
    };

    // Set initial date based on props
    const initialDate = parseDate(date) || new Date();
    const [selectedDate, setSelectedDate] = useState(initialDate);

    useEffect(() => {
        const parsedDate = parseDate(date);
        if (parsedDate) {
            setSelectedDate(parsedDate);
        }
    }, [date]);

    const handleChange = (date) => {
        setSelectedDate(date);
        if (onChange) {
            onChange(format(date, 'dd.MM.yyyy'));
        }
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="datepicker">
            <label className="datepicker__label">Дата</label>
            <div className="datepicker__custom-input" onClick={onClick} ref={ref}>
                {value}
                <i className="datepicker__icon icon-calendar"></i>
            </div>
            <input type="hidden" name="date" value={moment(value, 'DD.MM.YYYY').toDate()} />
        </div>
    ));

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleChange}
            customInput={<CustomInput />}
            locale={ru}
            dateFormat="dd.MM.yyyy"
        />
    );
};

export default CustomDatePicker;
